<template>
    <!-- form -->
    <form class="validate-form">
    <!-- core job detail -->
    <base-card-plain>
        <template #header>
            <h4 class="card-title">Job title & Overview</h4>
            <base-card-drop-down>
                <template #title>
                    JD options
                </template>
                <template #default>
                    <a class="dropdown-item" href="#">
                        <i class="bi bi-check-circle bs-icon-tiny" ></i>
                        Create JD from scratch
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="bi bi-circle bs-icon-tiny" ></i>
                        Duplicate existing JD
                    </a>
                    <a class="dropdown-item" href="#" v-if="useExistingJD">
                        <i class="bi bi-circle bs-icon-tiny" ></i>
                        Load existing JD
                    </a>
                </template>
            </base-card-drop-down>
        </template>
        <template #default>
                <div class="row">
                    <!-- header section -->
                    <div class="d-flex">
                        <a href="#" class="me-25">
                            <base-image
                                 imgName="avatar-s-11.jpg"
                                 imgGeo="portrait/small"
                                 imgAlt="profile image"
                                 imgClass="rounded me-50"
                                 :imgDim="[80,80]"
                                 >
                             </base-image>
                        </a>
                        <!-- upload and reset button -->
                        <div class="mt-75 ms-1">
                            <label for="account-upload" class="btn btn-sm btn-primary mb-75 me-75">Upload</label>
                            <input type="file" id="account-upload" hidden accept="image/*" />
                            <p>Allowed JPG, GIF or PNG. Max size of 800kB</p>
                        </div>
                        <!--/ upload and reset button -->
                    </div>
                    <!--/ header section -->
                    <div class="col-12 col-sm-12 mt-2">
                        <div class="mb-2">
                            <label class="form-label" for="account-birth-date">Organization</label>
                            <input type="text" class="form-control" placeholder="Organization name" id="account-birth-date" name="dob" />
                        </div>
                    </div>

                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" for="account-birth-date">Contact phone. no</label>
                            <input type="text" class="form-control" placeholder="Contact Phone. no" id="account-birth-date" name="dob" />
                        </div>
                    </div>

                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" for="account-birth-date">Contact email</label>
                            <input type="text" class="form-control" placeholder="Enter Email" id="account-birth-date" name="dob" />
                        </div>
                    </div>

                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" for="account-birth-date">Website</label>
                            <input type="text" class="form-control" placeholder="Organization name" id="account-birth-date" name="dob" />
                        </div>
                    </div>

                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" for="account-birth-date">Address</label>
                            <input type="text" class="form-control" placeholder="Enter physical address" id="account-birth-date" name="dob" />
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 mb-1 mt-1">
                            <label class="form-label" for="basicSelect">Areas of interest </label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Add areas of interest" aria-describedby="button-addon2" />
                                <button class="btn btn-outline-primary" id="button-addon2" type="button">
                                    <i class="bi bi-plus bs-icon-btn" ></i>
                                </button>
                            </div>
                            <div class="mt-1">
                                <base-badge  badgeColor="primary">Active skill</base-badge>
                                <base-badge  badgeColor="primary">Active skill</base-badge>
                                <base-badge  badgeColor="primary">Active skill</base-badge>
                            </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-1">
                            <label class="form-label" for="basicSelect">Status</label>
                            <select class="form-select" id="basicSelect">
                                <option>Active</option>
                                <option>Inactive</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-1">
                            <label class="form-label" for="accountTextarea">Organization Bio</label>
                            <textarea class="form-control" id="accountTextarea" rows="4" placeholder="Your Bio data here..."></textarea>
                        </div>
                    </div>

                    <div class="col-12">
                        <base-button btnColor="primary" >Save information</base-button>
                    </div>
                </div>
        </template>
    </base-card-plain>
    <!--Core job detail -->

    </form>
    <!--/ form -->
</template>

<script>


export default{
    props:{
        useExistingJD:{
            default:false,
            type: Boolean,
            required: false
        }
    }
}


</script>
