<template>


    <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper container-xxl p-0">
            <div class="content-header row">
                <div class="content-header-left col-md-9 col-12 mb-2">
                    <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h2 class="content-header-title float-start mb-0">Third Party Organizations</h2>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">manage third party organizations</a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-body">
                <!-- account setting page -->
                <section id="page-account-settings">
                    <div class="row">
                        <!-- left menu section -->
                        <div class="col-md-3 mb-2 mb-md-0">
                            <ul class="nav nav-pills flex-column nav-left">
                                <!-- Job description -->
                                <li class="nav-item">
                                    <a class="nav-link active" id="register-3rd-party-pill" data-bs-toggle="pill" href="#register-3rd-party" aria-expanded="true">
                                        <i class="bi bi-bank2 bs-icon-btn-tiny font-medium-3 mb-1 me-2" ></i>
                                        <span class="fw-bold">Register 3rd-party organization</span>
                                    </a>
                                </li>
                                <!-- Options -->
                                <li class="nav-item">
                                    <a class="nav-link" id="list-3rd-party-pill" data-bs-toggle="pill" href="#list-3rd-party" aria-expanded="false">
                                        <i class="bi bi-card-list bs-icon-btn-tiny font-medium-3 mb-1 me-2" ></i>
                                        <span class="fw-bold">List of 3rd-party organizations</span>
                                    </a>
                                </li>

                            </ul>
                        </div>
                        <!--/ left menu section -->

                        <!-- right content section -->
                        <div class="col-md-9">
                            <div class="card">
                                <div class="card-body">
                                    <div class="tab-content">
                                        <!-- post-job-pill-jd -->
                                        <div role="tabpanel" class="tab-pane active" id="register-3rd-party" aria-labelledby="register-3rd-party-pill" aria-expanded="true">


                                            <create-job-third-party>
                                            </create-job-third-party>



                                        </div>
                                        <!--/ post-job-pill-jd -->

                                        <!-- post-job-pill-option -->
                                        <div class="tab-pane fade" id="list-3rd-party" role="tabpanel" aria-labelledby="list-3rd-party-pill" aria-expanded="false">
                                            <!-- form -->
                                            <recruitment-third-party-list>
                                            </recruitment-third-party-list>
                                            <!--/ form -->
                                        </div>
                                        <!--/ post-job-pill-option -->


                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--/ right content section -->
                    </div>
                </section>
                <!-- / account setting page -->

            </div>
        </div>
    </div>
    <!-- END: Content-->


</template>

<script>
// import the core functions from vuex
import { useStore } from 'vuex';

import CreateJobThirdParty from '@/components/forms/recruitment/CreateJobThirdParty.vue';
import RecruitmentThirdPartyList from '@/components/recruitment/RecruitmentThirdPartyList';

export default{
    components:{
        CreateJobThirdParty,
        RecruitmentThirdPartyList
    },
    setup(){
        // get the global vuex store values
        const store = useStore();

        // use the dispatch function to call an action that calls a mutation
        store.dispatch("globals/executeSetNavMenu",true);
    }
}


</script>

<style scoped>
.bs-icon-small{
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1.5rem;
}

.bs-icon-tiny{
    height: 0.5rem;
    width: 0.5rem;
    font-size: 0.5rem;
    margin-right: 1.1rem;
    flex-shrink: 0;}


</style>
